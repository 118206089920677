import React, { Fragment, useState } from "react";
import { animated, useSpring } from "react-spring";

const AnimatedInput = ({
    error,
    label,
    onChange,
    optional,
    placeholder,
    type = "input",
    value
}) => {
    const [ isFocused, setIsFocused ] = useState(false);
    const inputSpring = useSpring({
        config: {
            mass: 1,
            tension: 300,
            friction: 12,
            duration: 100
        },
        // transform: isFocused ? "scale(1.025)" : "scale(1)",
        boxShadow: isFocused ? "0 5px 5px -5px rgba(0,0,0,0.4)" : "0px 0px 0px 0px rgba(0,0,0,0)"
    });
    const labelSpring = useSpring({
        config: {
            mass: 1,
            tension: 300,
            friction: 12
        },
        // transform: isFocused ? "scale(1.025)" : "scale(1)"
    });
    const inputClassName = error.hasError
        ? isFocused && value.length > 0
            ? "border border-primary"
            : "border border-error-dark"
        : isFocused
            ? "border border-primary"
            : "border"
    ;

    return (
        <Fragment>
            <animated.label
                htmlFor={label.for}
                className="text-secondary"
                style={labelSpring}
            >
                {label.name}
                {optional && <span className="text-xs text-muted float-right italic">optional</span>}
                {error.hasError
                    ? isFocused 
                        ? value.length < 1
                            ? <span className="ml-3 text-xs text-error-dark float-right italic">{error.errorMessage}</span>
                            : null
                        : <span className="ml-3 text-xs text-error-dark float-right italic">{error.errorMessage}</span>
                    : null
                }
            </animated.label>
            {type === "input"
                ? (
                    <animated.input
                        name={label.for}
                        type="text"
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        className={inputClassName}
                        style={inputSpring}
                        required={optional ? false : true}
                        aria-required={optional ? false : true}
                        aria-label={label.name}
                    />
                ) : (
                    <animated.textarea
                        name={label.for}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        className={inputClassName}
                        style={inputSpring}
                        aria-label={label.name}
                    />
                )
            }
        </Fragment>
    );
};

export default AnimatedInput;