import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Button.scss";

const Button = ({
    className,
    icon,
    label,
    onClick,
    type,
    style,
    ariaLabel
}) => {
    return (
        <button
            type={type}
            onClick={onClick}
            className={`btn ${className}`}
            style={style}
            aria-label={ariaLabel}
        >
            {icon && <FontAwesomeIcon icon={icon} aria-hidden="true" />}
            <span>{label}</span>
        </button>
    );
};

export default Button;