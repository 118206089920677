module.exports = formValues => {
    let hasErrors = false;
    let fieldErrors = {
        name: {
            hasError: false,
            errorMessage: ""
        },
        email: {
            hasError: false,
            errorMessage: ""
        },
        message: {
            hasError: false,
            errorMessage: ""
        }
    };
    const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;

    Object.keys(formValues).forEach(key => {
        if(key === "name" || key === "email"){
            if(formValues[key].trim().length === 0){
                hasErrors = true;

                fieldErrors = {
                    ...fieldErrors,
                    [key]: {
                        hasError: true,
                        errorMessage: "This field is required"
                    }
                }

                return;
            }
        }

        if(key === "email" && !emailRegEx.test(formValues[key])){
            hasErrors = true;
            fieldErrors = {
                ...fieldErrors,
                [key]: {
                    hasError: true,
                    errorMessage: "A valid email is required"
                }
            }
        }
    });

    return {
        hasErrors,
        fieldErrors
    };
};