import React, { useState } from "react";
import { Link } from "gatsby";
import { useSpring, animated } from 'react-spring'

import "./AnimatedLink.scss";

const AnimatedLink = ({
    path,
    label,
    borderColor,
    className
}) => {
    const [ isHovering, setIsHovering ] = useState(false);
    const animationProps = useSpring({
        config: {
            duration: 100
        },
        backgroundColor: borderColor,
        width: isHovering ? "100%" : "0%",
    });

    return (
        <div className={`inline-flex flex-col ${className}`}>
            <Link
                to={path}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                aria-label={label}
            >
                {label}
            </Link>
            <animated.div className="animated-border" style={animationProps}></animated.div>
        </div>
    );
};

export default AnimatedLink;