import { useEffect, useState } from "react";

let defaultWidth = 0;

if (typeof window !== "undefined") {
    defaultWidth = window.window.innerWidth;
}

const useScreenSize = breakpoint => {
    const [ isMobile, setIsMobile ] = useState(defaultWidth < breakpoint ? true : false);

    useEffect(() => {
        const setScreenWidth = newWidth => {
            defaultWidth = newWidth;

            if(isMobile && newWidth > (breakpoint - 1)){
                setIsMobile(false);
            } else if(!isMobile < breakpoint){
                setIsMobile(true);
            }
        };

        window.addEventListener("resize", () => setScreenWidth(window.innerWidth));

        return () => window.removeEventListener("resize", () => setScreenWidth(window.innerWidth));
    }, []);

    return isMobile;
};

export default useScreenSize;