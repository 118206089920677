import React from "react";

import "./Section.scss";

const Section = ({
    children,
    margin = "my-16",
    fluid = false,
    blogPost = false
}) => {
    return (
        <section className={`${fluid ? "fluid" : "container mx-auto px-4 md:px-0"} section ${margin}`}>
            {children}
        </section>
    );
};

export default Section;