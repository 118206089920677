import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Alert.scss";
import Button from "../Button";

const Alert = ({
    type,
    title,
    message,
    icon,
    dismissAlert
}) => {
    return (
        <div className="alert-container">
            <div className="overlay"></div>
            <div
                className={`alert shadow-md p-5 ${type === "primary" ? "bg-primary-light border-primary" : "bg-error-light border-error"} border-t-4`}
                role="alert"
            >
                <div className="flex text-secondary">
                    <div className={`py-1 ${type === "primary" ? "text-primary" : "text-error"}`}>
                        <FontAwesomeIcon icon={icon} />
                    </div>
                    <div className="ml-4">
                        <p className="font-bold">{title}</p>
                        <p className="text-sm">{message}</p>
                    </div>
                </div>
                <div className="mt-5 w-1/3 mx-auto">
                    <Button
                        type="button"
                        label="Got it!"
                        ariaLabel="Close"
                        onClick={dismissAlert}
                        className={`${type === "primary" ? "bg-primary hover:bg-primary-dark" : "bg-error hover:bg-error-dark"} text-white`}
                    />
                </div>
            </div>
        </div>
    );
};

export default Alert;