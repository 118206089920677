import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AOS from 'aos';
import 'aos/dist/aos.css';

import "../../css/global.scss";
import "./Layout.scss";

import Alert from "../Alert";
import { DesktopNavigation, MobileNavigation } from "../Navigation";
import Header from "../Header";
import ContactForm from "../ContactForm";
import Footer from "../Footer";
import { useScreenSize, useScrollDirection, useScrollPosition } from "../../hooks";

if (typeof window !== "undefined") {
    require("smooth-scroll")("a[href*='#']");
}

const navLinks = [
    { path: "/", label: "About" },
    { path: "/projects", label: "Projects" },
    { path: "/blog", label: "Blog" },
    { path: "/contact", label: "Contact" }
];

const Layout = ({
    headerChildren,
    children,
    path
}) => {
    useEffect(() => {
        AOS.init({
            offset: 80
        });
    }, []);

    const isMobile = useScreenSize(768);
    const isScrollAtTop = useScrollPosition();
    const [ scrollDirection, setScrollDirection ] = useScrollDirection("up", 64);
    const [ alert, setAlert ] = useState({
        showAlert: false,
        type: "",
        title: "",
        message: "",
        icon: ""
    });

    return (
        <div className="Layout bg-white h-full">
            {alert.showAlert && <Alert 
                type={alert.type}
                title={alert.title}
                icon={alert.icon}
                message={alert.message}
                dismissAlert={() => setAlert({
                    showAlert: false,
                    type: "",
                    title: "",
                    message: "",
                    icon: ""
                })}
            />}
            <MobileNavigation navLinks={navLinks} scrollDirection={scrollDirection} setScrollDirection={setScrollDirection} isScrollAtTop={isScrollAtTop} activeLink={path} />
            <DesktopNavigation navLinks={navLinks} isScrollAtTop={isScrollAtTop} activeLink={path} />
            <Header>
                {headerChildren}
            </Header>
            <main className={`${isMobile ? "mobile" : "desktop"} flex flex-col flex-grow`}>
                {children}
                <ContactForm setAlert={setAlert} />
                <Footer />
            </main>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node,
};

export default Layout;