import React from "react";
import { Link } from "gatsby";

import "./Logo.scss";

const Logo = () => {
    return (
        <Link
            to="/"
            aria-label="Home Page"
        >
            <div className="logo">
                <span className="thin">DylanOLeary</span>
                <span className="thick">.dev</span>
            </div>
        </Link>
    );
};

export default Logo;