import React, { useRef, useState } from "react";
import { Link } from "gatsby";
import { useChain, useSpring, useTrail, animated } from 'react-spring';
import { faBars } from "@fortawesome/free-solid-svg-icons";

import Button from "../Button";
import Logo from "../Logo";
import "./Navigation.scss";

const MobileNavigation = ({
    activeLink,
    isScrollAtTop,
    setScrollDirection,
    scrollDirection,
    navLinks 
}) => {
    const [ menuOpen, setMenuOpen ] = useState(false);
    const [ springEnabled, setSpringEnabled ] = useState(false);

    const headerSpring = useSpring({
        config: {
            duration: 250
        },
        marginTop: (scrollDirection === "down" && !menuOpen) ? -75 : 0
    });

    const menuRef = useRef();
    const menuProps = useSpring({
        config: {
            duration: 250
        },
        height: menuOpen ? navLinks.length * 65 : 0,
        ref: menuRef
    });

    const trailsRef = useRef();
    const trailConfig = {
        ref: trailsRef,
        from: {
            opacity: 0,
            width: "0%"
        },
        to: {
            opacity: 1,
            width: "100%"
        },
        reverse: !menuOpen
    };
    trailConfig.config = menuOpen
        ? (
            {
                mass: 1,
                tension: 230,
                friction: 18,
            }
        ) : (
            {
                duration: 275
            }
        )
    ;

    const trail = useTrail(navLinks.length, trailConfig);

    useChain(menuOpen ? [menuRef, trailsRef] : [trailsRef, menuRef], [0, 0.175]);

    const toggleMenu = () => {
        if(!springEnabled) setSpringEnabled(true);

        if(scrollDirection === "down" && menuOpen) setScrollDirection("up")
        setMenuOpen(!menuOpen);
    };

    return (
        <div
            className="mobile nav w-full md:hidden"
            style={{
                boxShadow: (isScrollAtTop && !menuOpen) ? "0px 0px 3px rgba(0, 0, 0, 0)" : "0px 0px 3px rgba(0, 0, 0, 0.8)",
                background: (isScrollAtTop && !menuOpen) ? "rgba(44, 158, 146, 0)" : "rgba(255,255,255,1)",
                borderBottom: (isScrollAtTop && !menuOpen) ? "0px solid #2C9E92" : "3px solid #2C9E92"
            }}
        >
            <animated.div
                className="flex justify-between items-center p-2 menu"
                style={headerSpring}
            >
                <div
                    style={{
                        color: (isScrollAtTop && !menuOpen) ? "#FFF" : "#2B1F44"
                    }}
                >
                    <Logo />
                </div>
                <Button 
                    className="toggle"
                    icon={faBars}
                    onClick={toggleMenu}
                    type="button"
                    style={{
                        color: (isScrollAtTop && !menuOpen) ? "#FFF" : "#2B1F44"
                    }}
                    ariaLabel={menuOpen ? "Close the menu" : "Open the menu"}
                />
            </animated.div>
            <animated.div
                style={{
                    height: !springEnabled ? 0 : "",
                    background: "#FFF",
                    ...menuProps
                }}
            >
                {trail.map((props, index) => {
                    return (
                        <animated.div
                            key={`mobile-nav-${index}`}
                            style={{ ...props }}
                            className="py-4 mobile-link"
                        >
                            <Link
                                to={navLinks[index].path}
                                className={navLinks[index].path === activeLink ? "text-primary" : "text-secondary"}
                            >
                                {navLinks[index].label}
                            </Link>
                        </animated.div>
                    );
                })}
            </animated.div>
        </div>
    );
};

export default MobileNavigation;