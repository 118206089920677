import React from "react";

import "./Header.scss";

const Header = ({ children }) => {
    return (
        <div className="header-wrapper">
            <div className="header bg-primary text-center">
                <div className="flex flex-col flex-auto items-center justify-center px-4">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Header;