import { useEffect, useRef, useState } from "react";

let defaultPosition = 0;

if (typeof window !== "undefined") {
    defaultPosition = window.window.scrollY;
}

const useScrollPosition = () => {
    const scrollPosition = useRef(defaultPosition);
    const [ isAtTop, setIsAtTop ] = useState(scrollPosition.current <= 10 ? true : false);

    useEffect(() => {
        window.addEventListener("scroll", () => setScrollPosition(window.scrollY));

        return () => window.removeEventListener("scroll", () => setScrollPosition(window.scrollY));
    }, []);

    const setScrollPosition = newPosition => {
        const previousPosition = scrollPosition.current;

        scrollPosition.current = newPosition

        if(scrollPosition.current <= 10 && previousPosition > 10){
            setIsAtTop(true);
        } else if(scrollPosition.current > 10 && previousPosition <= 10){
            setIsAtTop(false)
        }
    };

    return isAtTop;
};

export default useScrollPosition;