import React, { useState } from "react";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFrownOpen, faSmileBeam } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";

import formValidation from "../../utils/formValidation";
import "./ContactForm.scss";
import Button from "../Button";
import Section from "../Section";
import AnimatedInput from "../AnimatedInput";

const ContactForm = ({ setAlert }) => {
    const [ state, setState ] = useState({
        formValues: {
            name: "",
            email: "",
            message: ""
        },
        isSubmitting: false,
        fieldErrors: {
            name: {
                hasError: false,
                errorMessage: ""
            },
            email: {
                hasError: false,
                errorMessage: ""
            },
            message: {
                hasError: false,
                errorMessage: ""
            }
        }
    });

    const handleInputChange = event => {
        const { name, value } = event.target;

        setState({
            ...state,
            formValues: {
                ...state.formValues,
                [name]: value
            }
        });
    };

    const handleSubmit = event => {
        event.preventDefault();

        const { formValues } = state;
        const validation = formValidation(formValues);

        setState({
            ...state,
            isSubmitting: true
        });

        if(validation.hasErrors){
            const { fieldErrors } = validation;

            setState({
                ...state,
                isSubmitting: false,
                fieldErrors
            });
        } else {
            axios({
                method: "POST",
                url: "https://formspree.io/mnqzpnbn",
                data: state.formValues
            }).then(() => {
                setState({
                    ...state,
                    formValues: {
                        name: "",
                        email: "",
                        message: ""
                    },
                    isSubmitting: false,
                    fieldErrors: {
                        name: {
                            hasError: false,
                            errorMessage: ""
                        },
                        email: {
                            hasError: false,
                            errorMessage: ""
                        },
                        message: {
                            hasError: false,
                            errorMessage: ""
                        }
                    }
                });
    
                setAlert({
                    showAlert: true,
                    type: "primary",
                    title: "Thanks for reaching out!",
                    message: "Your message was sent successfully. I'll get back to you as soon as I can.",
                    icon: faSmileBeam
                });
            }).catch(() => {
                setState({
                    ...state,
                    isSubmitting: false
                });
    
                setAlert({
                    showAlert: true,
                    type: "error",
                    title: "Oops! (This isn't your fault)",
                    message: "Something went wrong when trying to submit your message. Please try again later!",
                    icon: faFrownOpen
                });
            });
        }
    };

    return (
        <Section>
            <form
                id="Contact"
                onSubmit={handleSubmit}
                data-aos="fade-up"
                data-aos-once="true"
                className="px-4"
            >
                <div className="mx-4 sm:mx-auto mb-6">
                    <h2 className="heading text-center mb-4">Give me a shout!</h2>
                    <p className="text-secondary text-center">
                        Have a question? Want to collaborate? I don't care, just reach out!
                    </p>
                </div>
                <div className="form-row">
                    <AnimatedInput
                        label={{
                            for: "name",
                            name: "Name"
                        }}
                        error={{
                            hasError: state.fieldErrors["name"].hasError || false,
                            errorMessage: state.fieldErrors["name"].errorMessage
                        }}
                        value={state.formValues.name}
                        onChange={handleInputChange}
                        type="input"
                        placeholder="Your Name Here"
                    />
                </div>
                <div className="form-row">
                    <AnimatedInput
                        label={{
                            for: "email",
                            name: "Email"
                        }}
                        error={{
                            hasError: state.fieldErrors["email"].hasError || false,
                            errorMessage: state.fieldErrors["email"].errorMessage
                        }}
                        value={state.formValues.email}
                        onChange={handleInputChange}
                        type="input"
                        placeholder="Your Email Here"
                    />
                </div>
                <div className="form-row">
                    <AnimatedInput
                        label={{
                            for: "message",
                            name: "Message"
                        }}
                        error={{
                            hasError: state.fieldErrors["message"].hasError || false,
                            errorMessage: state.fieldErrors["message"].errorMessage
                        }}
                        value={state.formValues.message}
                        onChange={handleInputChange}
                        type="textarea"
                        placeholder="Your (comments, tips, tricks, jokes, etc) here"
                        optional
                    />
                </div>
                <div className="form-row">
                    <Button
                        type="submit"
                        label={state.isSubmitting ? "Sending..." : "Submit"}
                        onClick={handleSubmit}
                        icon={faEnvelope}
                        className="bg-primary hover:bg-primary-dark focus:bg-primary-dark text-white"
                        ariaLabel="Submit"
                    />
                </div>
            </form>
        </Section>
    );
};

export default ContactForm;