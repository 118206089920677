import React from "react";
import { useSpring, animated } from 'react-spring'

import AnimatedLink from "../AnimatedLink";
import Logo from "../Logo";
import "./Navigation.scss";

const DesktopNavigation = ({
    activeLink,
    isScrollAtTop,
    navLinks
}) => {
    const containerProps = useSpring({
        config: {
            duration: 100
        },
        background: isScrollAtTop ? "rgba(44, 158, 146, 0)" : "rgba(255,255,255,1)",
        boxShadow: isScrollAtTop ? "0px 0px 0px #999" : "0px 1px 3px #999",
        borderBottom: isScrollAtTop ? "0px solid #2C9E92" : "3px solid #2C9E92"
    });

    const linkProps = useSpring({
        config: {
            duration: 100
        },
        color: isScrollAtTop ? "#FFF" : "#2B1F44"
    });

    const activeLinkClassName = isScrollAtTop ? "text-secondary" : "text-primary"

    return (
        <animated.div
            className="desktop nav w-full hidden md:block"
            style={containerProps}
        >
            <div className="container mx-auto flex justify-between items-center">
                <div className="w-1/2 my-auto">
                    <animated.div style={linkProps}>
                        <Logo />
                    </animated.div>
                </div>
                <div className="w-1/2 flex justify-end my-auto nav-links">
                    {navLinks.map(link => (
                        <animated.div
                            style={linkProps}
                            key={`nav-${link.label}`}
                        >
                            <AnimatedLink
                                path={link.path}
                                label={link.label}
                                borderColor={isScrollAtTop ? "#2B1F44" : "#2C9E92"}
                                className={`ml-6 ${link.path === activeLink ? activeLinkClassName : ""}`}
                            />
                        </animated.div>
                    ))}
                </div>
            </div>
        </animated.div>
    );
};

export default DesktopNavigation;